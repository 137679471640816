import React from 'react'

function DefaultFallback() {
  return <p className="text-sm italic">Loading...</p>
}

export default function ClientLazy({
  component,
  fallback,
  ...props
}: {
  component: any
  fallback?: any
  [x: string]: any
}) {
  const [Component, setComponent] = React.useState(() => fallback)

  React.useEffect(() => {
    setComponent(() => React.lazy(component))
  }, [])

  return (
    <React.Suspense fallback={<DefaultFallback />}>
      {Component && <Component {...props} />}
    </React.Suspense>
  )
}
